<template>
  <div class="main">
    <div class="wrapper">
      <div v-if="isLoading">
        <v-sheet height="100vh">
          <div class="flex">
            <div class="flex flex-column">
              <span class="text-center text-h5 green--text mt-3">
                Wrapping up your order
              </span>
              <div class="flex justify-center my-3">
                <v-progress-circular
                  indeterminate
                  color="green"
                ></v-progress-circular>
              </div>
            </div>
          </div>
        </v-sheet>
      </div>

      <!-- <component :is="recent.component" /> -->
      <transition name="fade">
        <component
          :is="navigation[franchise.list_style == 'list' ? 0 : 1].component"
        />
      </transition>
      <!-- <v-alert color="blue-grey" outlined icon="mdi-alpha-p-circle" v-if="franchise.policy && franchise.policy !== ''">
        {{ franchise.policy }}
      </v-alert> -->
      <div
        class="products_view_order_button"
        v-if="cart.length > 0 && !dialogHistory"
      >
        <v-btn
          width="25%"
          v-if="compareOrder.length > 0"
          dark
          color="#F85757"
          style="height: 65px; width: 50px"
          class="d-flex justify-space-center pa-2 pr-6 pl-6"
          rounded-xl
          elevation="0"
          @click="toggleHistory(true)"
        >
          <v-icon class="mr-2">mdi-history</v-icon>
        </v-btn>

        <v-btn
          :width="compareOrder.length > 0 ? '75%' : '100%'"
          color="#F85757"
          style="height: 65px"
          class="d-flex justify-space-between ml-3 pa-2 pr-6 pl-6"
          large
          dark
          rounded-xl
          elevation="0"
          @click="goToCart"
        >
          <!-- <v-icon class="mr-2">mdi-cart-plus</v-icon> -->
          <div style="text-transform: none; font-size: 15px">
            Cart : {{ renderQuantity(cart) }} Item
          </div>
          <div>{{ this.total.toFixed(2) }}</div>
        </v-btn>
      </div>
      <div
        class="history_sticky_button"
        v-if="!dialogHistory && compareOrder.length > 0 && cart.length == 0"
      >
        <v-btn
          block
          color="#F85757"
          style="height: 65px"
          class="d-flex justify-space-between pa-2 pr-6 pl-6"
          large
          dark
          rounded-xl
          elevation="0"
          @click="toggleHistory(true)"
        >
          <v-icon class="mr-2">mdi-history</v-icon>
          <div style="text-transform: none; font-size: 15px">History</div>
        </v-btn>
      </div>

      <div v-if="dialogHistory">
        <v-dialog
          transition="dialog-bottom-transition"
          v-model="dialogHistory"
          overlay-color="white"
          overlay-opacity="2"
          style="z-index: 1000 !important"
          max-width="500px"
        >
          <div v-if="compareOrder.length > 0" style="padding: 15px">
            <br />
            <v-card
              v-for="(v, i) in this.compareOrder.reverse()"
              :key="i"
              class="rounded-lg mx-auto mb-4"
              color="white"
              elevation="5"
              max-width="1150"
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline font-weight-bold">
                    {{ v.datetime }}
                    <v-divider></v-divider>
                  </div>
                  <div v-for="(item, index) in v.cart" :key="index">
                    <div class="history__pages__list_items__container">
                      <div class="history__pages__list_items_left">
                        <div class="history__pages__list_items_left_quantity">
                          <h5 class="white--text">x{{ item.quantity }}</h5>
                        </div>
                      </div>
                      <div class="history__pages__list_items_center">
                        <v-row class="justify-space-between pr-4">
                          <div style="font-weight: 600">{{ item.name }}</div>
                          <!-- Conditional Price Display -->
                          <div style="font-weight: 600">
                            RM
                            <!-- Check if variant exists and has a price -->
                            <span
                              v-if="
                                item.variant &&
                                item.variant.length > 0 &&
                                item.variant[0].price
                              "
                            >
                              {{ currency(item.variant[0].price) }}
                            </span>
                            <!-- Fallback to unit price if no variant price -->
                            <span v-else>
                              {{ currency(item.unit_price) }}
                            </span>
                          </div>
                        </v-row>

                        <!-- Existing Variant Details -->
                        <v-row
                          v-if="
                            item.variant &&
                            item.variant.length > 0 &&
                            item.price_type == 0
                          "
                          class="justify-space-between pr-4"
                        >
                          <span
                            class="flex mt-1"
                            v-for="(values, indexs) in item.variant"
                            :key="indexs"
                          >
                            <div class="grey--text" style="font-style: italic">
                              {{ showAmt(values) }}
                            </div>
                          </span>
                        </v-row>

                        <!-- Modifiers Section -->
                        <div class="mt-4" v-if="item.modifiers.length > 0">
                          <v-row
                            class="justify-space-between pr-4"
                            v-for="(val, ind) in renderModifierWithQuantity(
                              item.modifiers
                            )"
                            :key="ind"
                          >
                            <div class="grey--text">{{ val.name }}</div>
                            <div class="grey--text">
                              +{{ val.amount.toFixed(2) }}
                            </div>
                          </v-row>
                        </div>

                        <!-- Remarks Section -->
                        <div
                          style="
                            font-weight: bold;
                            margin-left: -12px;
                            margin-top: 14px;
                          "
                          v-if="item.remarks"
                        >
                          <h5 class="font-weight-bold">Remarks:</h5>
                          <h5 class="font-weight-bold blue-grey--text">
                            {{ item.remarks }}
                          </h5>
                        </div>
                      </div>
                    </div>

                    <v-divider></v-divider>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-container grid-list-xs class="px-0 pb-0 mt-0">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-card
                      color="white"
                      class="rounded-t-xl pa-3 pt-0"
                      elevation="0"
                    >
                      <div>
                        <!-- summary -->
                        <v-row>
                          <v-col cols="12" class="pb-0">
                            <v-card class="rounded-lg" elevation="0">
                              <div class="pa-3 source black--text">
                                <div class="flex justify-space-between">
                                  <h2>Total</h2>
                                  <h2 class="lato">
                                    RM
                                    {{ currency(v.totalOrder) }}
                                  </h2>
                                </div>
                                <div
                                  style="
                                    font-size: 0.6rem;
                                    font-style: italic;
                                    margin-top: 5px;
                                  "
                                >
                                  *The total amount includes SST, taxes, and
                                  other applicable charges.
                                </div>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-btn
              block
              color="#F85757"
              style="height: 40px"
              class="d-flex justify-space-between pa-2 pr-6 pl-6"
              large
              dark
              rounded-xl
              elevation="0"
              @click="toggleHistory(false)"
            >
              <!-- <v-icon class="mr-2">mdi-cart-plus</v-icon> -->
              <div style="text-transform: none; font-size: 15px">Close</div>
            </v-btn>
            <!-- end of history detail -->
          </div>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Product",
  data() {
    return {
      total: 0,
      isLoading: false,
      status: null,
      dialogHistory: false,
      navigation: [
        { title: "list", component: () => import("./List") },
        { title: "grid", component: () => import("./Grid") },
        { title: "slide", component: () => import("./Slide") },
      ],
      // recent: { component: () => import("./Recent") },
    };
  },

  computed: {
    ...mapGetters({
      franchise: "getFranchise",
      user: "getUsers",
      cart: "getCart",
      order: "getOrder",
    }),
    selectedUser() {
      return this.user.find((d) => d.selected == true);
    },
    compareOrder() {
      if (!this.selectedUser) return [];
      return this.order.filter(
        (d) => d.customer.name == this.selectedUser.name
      );
    },
    dayjs() {
      return require("dayjs");
    },
    currency() {
      return require("currency.js");
    },
  },

  watch: {
    cart: {
      handler: "calculate",
      immediate: true,
    },
  },
  methods: {
    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },
    renderModifierWithQuantity(arr) {
      let modifiers = arr;
      const totals = {};
      // Iterate over the data array
      modifiers.forEach((item) => {
        const { id, name, mode, amount } = item;

        // Check if the id exists in the totals object
        if (totals[id]) {
          // If it exists, increment the total
          totals[id].total++;
        } else {
          // If it doesn't exist, create a new entry
          totals[id] = { id, name, mode, amount, total: 1 };
        }
      });
      // Convert the totals object into an array
      const result = Object.values(totals);
      return result;
    },
    showAmt(vars) {
      if (typeof vars.price == "string") {
        vars.price = parseFloat(vars.price);
      }

      return `${vars.name}`;
    },
    async calculate() {
      try {
        const total = await this.$store.dispatch("cartTotal");
        this.total = total;
      } catch (error) {
        console.error("Error Calculating total", error);
      }
    },
    toggleHistory(status) {
      console.log(JSON.parse(JSON.stringify(this.status)));
      console.log(status);
      this.dialogHistory = status;
    },
    renderQuantity(carts) {
      let totalSum = 0;

      carts.forEach((obj) => {
        totalSum += obj.quantity;
      });
      return totalSum;
    },
    goToCart() {
      const { outlet_id, table_id } = this.$route.params;
      const { session } = this.$route.params;
      if ((outlet_id, table_id)) {
        // STATIC QR
        this.$router.push({
          name: "Cart",
          params: {
            child: this.$route.params.child,
            outlet_id: outlet_id,
            table_id: table_id,
            page: "cart",
          },
        });
      } else if (session) {
        // SESSION QR
        this.$router.push({
          name: "SessionCart",
          params: { session },
        });
      } else {
        // SHOPL LINK
        this.$router.push({
          name: "ShoplinkCart",
          params: { child: this.$route.params.child, page: "cart" },
        });
      }
    },
  },

  beforeMount() {
    if (this.order) {
      this.status = this.order;
    }
  },
};
</script>
<style lang="scss">
.products_view_order_button {
  position: sticky;
  bottom: 0px;
  padding-top: 10px;
  display: flex;
  padding-left: 10px;
  background-color: white;
  padding-bottom: 20px;
  padding-right: 15px;
  z-index: 1000000;
  width: 100%;
}

.history_sticky_button {
  position: sticky;
  bottom: 0px;
  padding-top: 10px;
  padding-left: 10px;
  background-color: white;
  padding-bottom: 20px;
  padding-right: 15px;
  z-index: 1000000;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.history__pages__list_items__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

.history__pages__list_items_left {
  width: 10%;
  justify-content: center;
  align-items: center;

  .history__pages__list_items_left_quantity {
    width: 30px;
    height: 30px;
    background-color: #f85757;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.history__pages__list_items_center {
  width: 80%;
}
</style>
